import Hometile from "../assets/home-tile.jpg"
import Businesstile from "../assets/business-tile.jpg"
import Producttile from "../assets/product-tile.jpg"

export default function Solutions() {
  const style = {
    height: "280px",
    backgroundColor: "#f7f7f7",
    backgroundImage: "url(" + Hometile + ")",
  };
  const style2 = {
    height: "280px",
    backgroundColor: "#f7f7f7",
    backgroundImage: "url(" + Businesstile + ")",
  };
  const style3 = {
    height: "280px",
    backgroundColor: "#f7f7f7",
    backgroundImage: "url(" + Producttile + ")",
  };
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-3 text-center">
            <h2>Solutions</h2>
          </div>
        </div>
        <div className="mt-5 row">
          <div className="col-lg-4 mt-3">
            <div className="contentlink">
              <aside className="contentbox" style={style}>
                <div className="overlay"></div>
                <div className="text" style={{ top: "140px" }}>
                  <h3>For Your Home</h3>
                  <div className="span" style={{ color: "#ffffff" }}>
                    Protecting your family from health pests
                  </div>
                </div>
              </aside>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="contentlink">
              <aside className="contentbox" style={style2}>
                <div className="overlay"></div>
                <div className="text" style={{ top: "140px" }}>
                  <h3>For Your Business</h3>
                  <div className="span" style={{ color: "#ffffff" }}>
                    Customized pest management for your Business
                  </div>
                </div>
              </aside>
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <div className="contentlink">
              <aside className="contentbox" style={style3}>
                <div className="overlay"></div>
                <div className="text" style={{ top: "140px" }}>
                  <h3>For Any Location</h3>
                  <div className="span" style={{ color: "#ffffff" }}>
                    Custimized Pest Management for your Premises.
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
