import { useMediaQuery } from 'react-responsive';
import Carousel from "react-bootstrap/Carousel";
import IMG1 from "../assets/carosol/safecare.jpg";
import MIMG1 from "../assets/carosol/m-safecare.jpg";
import IMG2 from "../assets/carosol/pest.jpg";
import MIMG2 from "../assets/carosol/m-pest.jpg";
import IMG3 from "../assets/carosol/security.jpg";
import MIMG3 from "../assets/carosol/m-security.jpg";
import IMG4 from "../assets/carosol/firefight.jpeg";
import MIMG4 from "../assets/carosol/m-fiire.jpg";
import IMG5 from "../assets/carosol/home.jpg";
import MIMG5 from "../assets/carosol/m-home.jpg";
import { Link } from 'react-router-dom';

export default function HeroHome() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <div className="top-margin max-w-6xl max-w-8xl mx-auto relative">
      <Carousel>
        <Carousel.Item interval={5000}>
        <Link to='/home'>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false?IMG1:MIMG1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Safe Care</h3>
            <p>Caring with Commitment</p>
          </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <Link to='/services/pest-control'>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false?IMG2:MIMG2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>Pest Control</h3>
            <p>
              To tackle effectively white ants problem and make the Building
              resistant to termite attack.
            </p>
          </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
        <Link to='/services/security-service'>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false?IMG3:MIMG3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Security Service</h3>
            <p>
              Against all crawling insects in the household situation i.e.
              cockroaches, Silver fish, Ticks Bugs, Spiders etc.
            </p>
          </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
        <Link to='/services/fire-security'>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false?IMG4:MIMG4}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Fire Fighting</h3>
            <p>To bring under control the household and field redents</p>
          </Carousel.Caption>
          </Link>
        </Carousel.Item>
        <Carousel.Item>
        <Link to='/services/security-service'>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false?IMG5:MIMG5}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>House Keeping</h3>
            <p>To liable housekeeping services.</p>
          </Carousel.Caption>
          </Link>
        </Carousel.Item>
        {/* <Carousel.Item>
          <img
            className="d-block w-100 c-mobile"
            src={IMG2}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Vector control</h3>
            <p>To combat mosquitoes and files effectively.</p>
          </Carousel.Caption>
        </Carousel.Item> */}
      </Carousel>
    </div>
  );
}
