import CountUp from "react-countup";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PersonIcon from "@mui/icons-material/Person";

export default function Number() {
  return (
    <div className="section max-w-6xl max-w-8xl mx-auto px-4">
      <section className="px-0 container justify-content-center">
        <div className="row text-center">
          <div className="col-lg-8 offset-lg-2">
            <h3>Safe Care Pvt. Ltd.</h3>
            <span className="mt-3">We provide Ghaziabad's best Pest control services, we have added
                Fire Fighting, House Keeping,and Security Services on the
                demands of our Clients and have established under the SAFE CARE.</span>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3 col-sm-6 col-xs-6">
            <div className="counter_box text-center">
              <div className="counter_icon">
                <ThumbUpIcon fontSize="large" />
              </div>
              <div className="counter_number counter">100%</div>
              <h4 className="counter_name">Quality</h4>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6">
            <div className="counter_box text-center">
              <div className="counter_icon">
                <PersonIcon fontSize="large" />
              </div>
              <div className="counter_number counter">
                <CountUp end={1000} duration={30} />+
              </div>
              <h4 className="counter_name">People Working</h4>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6">
            <div className="counter_box text-center">
              <div className="counter_icon">
                <DateRangeIcon fontSize="large" />
              </div>
              <div className="counter_number counter">
                <CountUp end={28} duration={4} /> Years
              </div>
              <h4 className="counter_name">Years Experience</h4>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-6">
            <div className="counter_box text-center">
              <div className="counter_icon">
                <SentimentSatisfiedAltIcon fontSize="large" />
              </div>
              <div className="counter_number counter">
                <CountUp end={2500} duration={30} />+
              </div>
              <h4 className="counter_name">Happy Smiles</h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
