import Logo from "../assets/logo-small.png";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";

function Footer() {
  return (
    <div>
      <div className="footerClass footer-10">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mb-4">
              <h3 className="footer-heading d-flex">About Us</h3>
              <span className="footer-p mt-2">
                We provide Ghaziabad's best Pest control services, we have added
                Fire Fighting, House Keeping,and Security Services on the
                demands of our Clients and have established under the SAFE CARE.
              </span>
            </div>
            <div className="col-md-4 mb-4">
              <h3 className="footer-heading">Services</h3>
              <ul className="mt-2 list-unstyled footer-p">
                <li>
                  <a href="/services/security-service">Security Service</a>
                </li>
                <li>
                  <a href="/services/pest-control">Pest Control</a>
                </li>
                <li>
                  <a href="/services/house-keeping">House Keeping</a>
                </li>
                <li>
                  <a href="/services/fire-safety">Fire Safety</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                <li>
                  <a href="/about">About Us</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 mb-4">
              <h3 className="footer-heading">Contact Us</h3>
              <ul className="mt-2 list-unstyled">
                <p className="footer-p">
                  <PlaceIcon className="mr-2" />
                  LGF - 34, Devika Chamber, RDC, Raj Nagar, Ghaziabad
                </p>
                <p className="footer-p">
                  <EmailIcon className="mr-2" />
                  safecare016@gmail.com
                </p>
                <p className="footer-p">
                  <PhoneIcon className="mr-2" />
                  +917011146759
                </p>
              </ul>
              <div className="mt-3">
                <ul className="h-social d-flex">
                  <li className="mr-2">
                    <a href="/#" target="_blank">
                      <FacebookIcon sx={{ color: "#ffffff" }}></FacebookIcon>
                    </a>
                  </li>
                  <li className="mr-2">
                    <a href="/#" target="_blank">
                      <WhatsAppIcon sx={{ color: "#ffffff" }}></WhatsAppIcon>
                    </a>
                  </li>
                  <li className="mr-2">
                    <a href="/#" target="_blank">
                      <TwitterIcon sx={{ color: "#ffffff" }}></TwitterIcon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-dotted">
        <div className="container">
          <div className="transformY50 shadow-sm bg-white">
            <div className="p-3 row font-14">
              <div className="col-md-12 col-lg-12">
                <div className="row justify-content-start">
                  <img className="col-lg-3 col-md-3" src={Logo} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-md-4 col-lg-3 mb-4 d-flex">
              <p className="copyright mb-0 ml-auto">
                © 2022 SAFE CARE, All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
<footer className="footer-10"></footer>;
