import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./ContactForm";
import { useState } from "react";

export default function HomeService() {
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  return (
    <section className="bg-homeservice section relative max-w-6xl max-w-8xl mx-auto px-4 pst_bottompadder70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12 col-sm-12 offset-lg-2 offset-md-2">
            <div className="sp_choose_heading_main_wrapper pst_bottompadder50">
              <h2>
                <span>Our Services</span>
              </h2>
              <p>
                Safe Care provides the following services with the best quality
                in the Ghaziabad &amp; NCR Region.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="maindiv mx-2">
              <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
              <span className="mx-2">WORK STATIONS</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="maindiv mx-2">
              <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
              <span className="mx-2">AC DUCTS</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="maindiv mx-2">
              <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
              <span className="mx-2">CEILING/FALSE CEILING</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="maindiv mx-2">
              <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
              <span className="mx-2">CABINS</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="maindiv mx-2">
              <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
              <span className="mx-2">FLOOR SCRUBBING</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="maindiv mx-2">
              <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
              <span className="mx-2">WOODEN FLOOR</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="maindiv mx-2">
              <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
              <span className="mx-2">WASHROOMS</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="maindiv mx-2">
              <DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>
              <span className="mx-2">CARPET</span>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button onClick={handleClick} className="tt-btn bg-green">
            Request a Quote
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </button>
          <Dialog open={isShown} onClose={handleClick}>
            <DialogTitle>Contact Form</DialogTitle>
            <DialogContent>{<ContactForm />}</DialogContent>
          </Dialog>
        </div>
      </div>
    </section>
  );
}
