import Employee from "../assets/employee.jpg";
import Training from "../assets/training.jpg";
import Service from "../assets/service.jpg";
import Professionalism from "../assets/professionals.jpg";

export default function WhyHire() {
  return (
    <section className="section">
      <div className="bg-dotted row justify-content-center">
        <div className="col-lg-3 col-md-3 col-sm-8 col-8 grey-wrap p-3">
            <h3>Why Hire Us?</h3>
            <span>Why should you choose our company</span>
        </div>
      </div>
      <div className="grey-wrap">
        <div className="container">
          <section className="row justify-content-around px-2">
            <div className="col-sm-3">
              <div className="maindiv box-info">
                <div>
                  <img src={Employee} alt="" />
                </div>
                <h4>Our Employees</h4>
                <p>
                  Our pay rates are higher than industry standards for the best
                  employees.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="maindiv box-info">
                <div>
                  <img src={Training} alt="" />
                </div>
                <h4>Our Training</h4>
                <p>
                  All of our employees receive cleaning methods and equipment
                  training.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="maindiv box-info">
                <div>
                  <img src={Service} alt="" />
                </div>
                <h4>Our Services</h4>
                <p>
                  We provide a wide array of professional cleaning services
                  perfect.
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="maindiv box-info">
                <div>
                  <img src={Professionalism} alt="" />
                </div>
                <h4>Professionalism</h4>
                <p>
                  We conduct frequent quality control inspections to ensure the
                  best services.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
}
