import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import Transition from "../utils/Transition";
import Logo from "../assets/logo-small.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ContactForm from "./ContactForm";

export default function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const trigger = useRef(null);
  const mobileNav = useRef(null);
  const [isShown, setIsShown] = useState(false);


  const handleClick = () => {
    setIsShown(current => !current);
  };

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="fixed-top w-100 z-30 shadow">
      <div className="bb-header pt-2 pb-2 pl-3 pr-3 row d-flex justify-content-between mobile-hidden">
        <div className="col-auto">
          <div className="d-flex">
            <div className="tt-item">
              <span className="text-sm tt-header-text">
                <LocationOnOutlinedIcon
                  sx={{ color: "#6984c3" }}
                ></LocationOnOutlinedIcon>
                LGF - 34, Devika Chamber, RDC, Raj Nagar, Ghaziabad
              </span>
            </div>
            <div className="tt-item">
              <span className="text-sm tt-header-text">
                <AccessTimeOutlinedIcon
                  sx={{ color: "#6984c3" }}
                ></AccessTimeOutlinedIcon>
                Mon-Fri 08:00 AM - 05:00 PM
              </span>
            </div>
            <div className="tt-item">
              <a
                className="text-sm tt-header-text"
                href="mailto: safecare016@gmail.com"
              >
                <EmailOutlinedIcon
                  sx={{ color: "#6984c3" }}
                ></EmailOutlinedIcon>{" "}
                safecare016@gmail.com
              </a>
            </div>
          </div>
        </div>
        <div className="mr-2 col-auto ml-auto">
          <ul className="h-social d-flex">
            <li className="mr-2">
              <a href="/#" target="_blank">
                <FacebookIcon sx={{ color: "#7a7b7b" }}></FacebookIcon>
              </a>
            </li>
            <li className="mr-2">
              <a href="/#" target="_blank">
                <WhatsAppIcon sx={{ color: "#7a7b7b" }}></WhatsAppIcon>
              </a>
            </li>
            <li className="mr-2">
              <a href="/#" target="_blank">
                <TwitterIcon sx={{ color: "#7a7b7b" }}></TwitterIcon>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mx-auto px-4">
        <div className="flex items-center justify-content-center mobile-justify-between h-20">
          {/* Site branding */}
          <div className="d-flex shrink-0 mr-5">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
              <img className="Killen-Logo" src={Logo} alt="Logo" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <div className="mobile-hidden flex grow lg:flex lg:grow">
            <nav>
              {/* Desktop menu links */}
              <ul className="flex grow flex-wrap items-center px-3 justify-content-center font-medium">
                <li>
                  <a
                    href="/home"
                    className="text-sm header-text px-3 py-2 flex items-center"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <Link
                    to="/about"
                    className="text-sm header-text px-3 py-2 flex items-center"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/pest-control"
                    className="flex py-2 px-3 header-text text-sm"
                  >
                    Pest Control
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/security-service"
                    className="flex py-2 px-3 header-text text-sm"
                  >
                    Security Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/fire-security"
                    className="flex py-2 px-3 header-text text-sm"
                  >
                    Fire Safety
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/house-keeping"
                    className="flex py-2 px-3 header-text text-sm"
                  >
                    House Keeping
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="text-sm header-text px-3 py-2 flex items-center"
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          {/* Desktop CTA on the right */}
          <div className="mobile-hidden">
            <div className="flex justify-end flex-wrap items-center">
              {/* <div onClick={handleClickOpen}>
                <Avatar></Avatar>
              </div> */}
              <button onClick={handleClick} className="tt-btn">
                Request a Quote
                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
              </button>
              <Dialog open={isShown} onClose={handleClick}>
                <DialogTitle>Contact Form</DialogTitle>
                <DialogContent>
                  {<ContactForm />}
                </DialogContent>
              </Dialog>
            </div>
          </div>

          <div className="inline-flex md-hidden">
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <Transition
              show={mobileNavOpen}
              tag="ul"
              className="fixed top-0 h-screen z-20 left-0 max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-x-full"
              enterEnd="opacity-100 translate-x-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              <nav
                id="mobile-nav"
                ref={mobileNav}
                className="fixed top-0 h-screen z-20 w-100 left-0 max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar"
              >
                <div className="py-6 pr-4 pl-20">
                  {/* Logo */}
                  <img className="Killen-Logo" src={Logo} alt="Logo" />
                  {/* Links */}
                  <ul>
                    <li>
                      <Link
                        to="/services/pest-control"
                        className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                      >
                        Pest Control
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/security-service"
                        className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                      >
                        Security Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/house-keeping"
                        className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                      >
                        House Keeping
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services/fire-safety"
                        className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                      >
                        Fire Safety
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        About Us
                      </Link>
                    </li>
                    {/* <li>
                      <a
                        href="/home#certificate"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        Our Certificate
                      </a>
                    </li> */}
                    <li>
                      <Link
                        to="/Contact"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="py-6 pr-4 pl-20 mt-auto">
                  <a
                    href="https://wa.me/917011146759?text=Hi%2C%20I%27m%20Interested%20in%20your%20Services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatsAppIcon
                      fontSize="large"
                      color="success"
                    ></WhatsAppIcon>
                  </a>
                </div> */}
              </nav>
            </Transition>
          </div>
        </div>
      </div>
    </header>
  );
}
