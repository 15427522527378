import { useState } from "react";
import Fire1 from "../assets/fire-1.jpg";
import Fire2 from "../assets/fire-2.jpg";
import Fire3 from "../assets/fire-3.jpg";
import Fire4 from "../assets/fire-4.jpg";
import FireAbout from "../assets/fire-about.jpg";
import FireWarn from "../assets/fire-warn.jpg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./ContactForm";

export default function FireSafety() {
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  return (
    <section className="section relative max-w-6xl max-w-8xl mx-auto px-4">
      <div className="container">
        <h1 className="text-center">Think of fire before it starts</h1>
        <div className="row mt-3 align-items-center justify-content-around">
          <div className="col-md-2 ">
            <div className="text-center">
              <img src={Fire1} className="rounded m-3" alt="..." />
              <h5>
                <b>
                  FIRE <br />
                  PREVENTION
                </b>
              </h5>
            </div>
            <div className="text-center mt-3">
              <p>
                The precaution to prevent potentially harmful fires, surviving
                and reducing the damage caused by emergencies.
              </p>
            </div>
          </div>
          <div className="col-md-2 mr-2">
            <div className="text-center">
              <img src={Fire2} className="rounded m-3" alt="..." />
              <h5>
                <b>DEPARTMENT INFORMATION</b>
              </h5>
            </div>
            <div className="text-center mt-3">
              <p>
                We provide you with practical actions, advice and resources to
                prepare for and cope during a range of emergencies.
              </p>
            </div>
          </div>
          <div className="col-md-2 mr-2">
            <div className="text-center">
              <img src={Fire3} className="rounded m-3" alt="..." />
              <h5>
                <b>
                  PUBLIC <br />
                  EDUCATION
                </b>
              </h5>
            </div>
            <div className="text-center mt-3">
              <p>
                The tools and information for teaching people of all ages about
                the fire and how to make choices regarding safety.
              </p>
            </div>
          </div>
          <div className="col-md-2 mr-2">
            <div className="text-center">
              <img src={Fire4} className="rounded m-3" alt="..." />
              <h5>
                <b>
                  STRATEGIC <br />
                  PLAN
                </b>
              </h5>
            </div>
            <div className="text-center mt-3">
              <p>
                The plan focuses on implementing advanced technologies,
                improving services and resiliency during emergency events.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="container mt-5">
        <div className="row">
          <div className="col-lg-6">
            <img src={FireAbout} className="img-fluid" alt="..." />
          </div>
          <div className="col-lg-6">
            <p>About Our Fire Operations</p>
            <h1>
              <b>Premier Fire &amp; Resue Services System</b>
            </h1>
            <p className="mt-5">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have sufferd alteration in some form, by injected
              humour, or randomised words which don't look even slightly
              believable.
            </p>
            <div className="row mt-5 mb-3">
              <img
                src={FireWarn}
                alt=""
                className="col-lg-8 col-md-8 col-12 col-sm-12"
              />
            </div>
            <li>If you are going to use a passage of you need</li>
            <li className="mt-3">
              Lorem Ipsum available, but the majority have suffered
            </li>
            <div className="row mt-3">
              <button
                onClick={handleClick}
                className="btn btn-danger btn-md col-lg-4 col-md-4 col-5 col-sm-8 mt-4"
              >
                <p className="m-1">Book Service</p>
              </button>
              <Dialog open={isShown} onClose={handleClick}>
                <DialogTitle>Contact Form</DialogTitle>
                <DialogContent>{<ContactForm />}</DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
