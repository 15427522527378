import AboutSecurity from "../components/AboutSecurity";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroHome from "../components/HeroHome";
import Number from "../components/Numbers";
import OurClients from "../components/OurClients";
// import SecurityType from "../components/SecurityType";
import ServiceSecurity from "../components/ServiceSecurity";
import WhatsApp from "../components/Call";
import WhyHire from "../components/WhyHire";

export default function Security() {
  return (
    <div className="d-flex flex-col min-h-screen overflow-hidden">
      {<Header />}
      {<HeroHome />}
      <main className="grow">
        {<AboutSecurity />}
        {<ServiceSecurity />}
        {/* {<SecurityType />} */}
        {<Number />}
        {<WhyHire />}
        {<OurClients />}
        {<WhatsApp />}
      </main>
      {<Footer />}
    </div>
  );
}
