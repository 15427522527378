import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./ContactForm";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useState } from "react";

export default function WhatsApp() {
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  return (
    <div>
      <button onClick={handleClick} className="whatsapp-button">
        <WhatsAppIcon sx={{ color: "#ffffff",fontSize: "48px" }}></WhatsAppIcon>
      </button>
      <Dialog open={isShown} onClose={handleClick}>
        <DialogTitle>Contact Form</DialogTitle>
        <DialogContent>{<ContactForm />}</DialogContent>
      </Dialog>
      <div onClick={handleClick} className="pop-service">
        <div className="pop-text">Book Service</div>
      </div>
    </div>
  );
}
