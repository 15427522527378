import IMG1 from "../assets/carosol/m-fiire.jpg";

export default function AboutFire() {
  return (
    <section className="about-services">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div>
              <img
                src={IMG1}
                className="img-fluid"
                alt="pest control services"
              />
            </div>
          </div>
          <div className="col-md-7 text-justify">
            <div>
              <h1 className="fw-bold text-uppercase fs-2 mb-3">
                Best Fire Fighters in Ghaziabad &amp; NCR
              </h1>
              <p>
                The growing dimensions of this phenomenon underline the need for
                increasingly sophisticated and means to counter the danger posed
                by these elements. There is only one way to effectively combat
                organized crime: by employing specialized personnel trained and
                equipped to neutralize the threat. A number of highly effective
                anti-crime methods and operational tools have been developed and
                employed at SAFE CARE.
              </p>
              <p className="mt-3">
                In its pursuit to achieve this aim. To achieve this, the company
                adopts a comprehensive approach from the word "GO" and while
                other aspects of security will deal with separately, in its
                offer of physical security. it offers the following services:-
              </p>
              <p className="mt-3">
                <li>Installation Security</li>
                <li>Personal Security</li>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
