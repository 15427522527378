const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
};

export class BaseService {
  constructor() {
    this.domain = "https://safe-care-api.herokuapp.com/users/";
  }
  request(url, method = "POST", data = null) {
    url = new URL(url, this.domain).href;
    const options = {
      headers,
      method,
    };
    if (data) {
      options.body = JSON.stringify({ ...data });
    }
    return fetch(url, options);
  }
  requestDoc(url, method = "POST", data = null) {
    url = new URL(url, this.domain).href;
    const options = {
      headers,
      method,
      responseType: "json",
    };
    if (data) {
      options.body = JSON.stringify({ ...data });
    }
    return fetch(url, options);
  }
  PostRequest(url, data) {
    const method = "POST";
    return this.request(url, method, data).then((res) => res.json());
  }
  GetRequest(url, id) {
    const method = "GET";
    if (id) {
      // fetch single record
      url = `${url}` / `${id}`;
    }
    return this.request(url, method).then((res) => res.json());
  }

  exportToCSV(url, body, fileName) {
    const method = "POST";
    this.requestDoc(url, method, body).then((response) => {
      response.blob().then((blob) => {
        console.log(blob);
        let link = document.createElement("a");
        if (link.download !== undefined) {
          let url = URL.createObjectURL(blob);
          console.log(url);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName + ".xlsx");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    });
  }
}
