import Time from "../assets/time2.png";
import Safety from "../assets/Safety2.png";
import Best from "../assets/best2.png";
import Equipped from "../assets/Equipped2.png";
import Touch from "../assets/touch2.png";
import Cash from "../assets/cash2.png";

export default function TrustSecurity() {
  return (
    <section
      id="trust-security"
      className="py-0 section relative max-w-6xl max-w-8xl mx-auto"
    >
      <div className="bg-dotted row justify-content-center">
        <div className="col-lg-3 col-md-3 col-sm-8 col-8 grey-wrap p-3">
          <h3>Your Trust and Security</h3>
          <span>Why should you choose our company</span>
        </div>
      </div>
      <div className="grey-wrap py-3 text-center">
        <section className="row px-3 text-center justify-content-around">
          <div className="col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
            <div className="box-trust">
              <div className="icons mx-auto">
                <img src={Time} alt="SAVES" />
              </div>
              <div className="box_content">
                <h4>SAVES YOU TIME</h4>
                <p>
                  We helps you live smarter, giving you time to focus on what's
                  most important.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
            <div className="box-trust">
              <div className="icons mx-auto">
                <img src={Safety} alt="Safety" />
              </div>
              <div className="box_content">
                <h4>For Your Safety</h4>
                <p>
                  All of our Helpers undergo rigorous identity checks and
                  personal interviews. Your safety is even our concern too.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
            <div className="box-trust">
              <div className="icons mx-auto">
                <img src={Best} alt="Best" />
              </div>
              <div className="box_content">
                <h4>Best-Rated Professionals</h4>
                <p>
                  Our experienced taskers perform their tasks with dedication
                  and perfection. We appreciate your reviews.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-0 row px-3 text-center justify-content-around">
          <div className="col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
            <div className="box-trust">
              <div className="icons mx-auto">
                <img src={Equipped} alt="Equipped" />
              </div>
              <div className="box_content">
                <h4>We Are Well Equipped</h4>
                <p>
                  Let us know if you have any specific equirement, otherwise our
                  guys carry their own supplies.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
            <div className="box-trust">
              <div className="icons mx-auto">
                <img src={Touch} alt="Always" />
              </div>
              <div className="box_content">
                <h4>Always In Touch</h4>
                <p>
                  Book your service online on one tap, keep a track of your
                  service status and also keep in touchs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
            <div className="box-trust">
              <div className="icons mx-auto">
                <img src={Cash} alt="cash" />
              </div>
              <div className="box_content">
                <h4>Cash-Free Facility</h4>
                <p>
                  Pay through secure online mode only after your job is done.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
