import IMG1 from "../assets/residential.png";
import IMG2 from "../assets/commercial.png";
import IMG3 from "../assets/resident-4.jpg";
import IMG4 from "../assets/commercial-4.jpg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./ContactForm";
import { useState } from "react";

export default function RCSanitizing() {
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  return (
    <section className="">
      <div className="row">
        <div className="col-lg-6 p-0">
          <img src={IMG1} className="img-fluid bg-dotted w-100" alt="" />
        </div>
        <div className="bg-sanitize col-lg-6">
          <div className="col-lg-8 p-3">
            <span>
              <h2>Residential Sanitizing</h2>
            </span>
            <div className="mt-3 pr-3">
              Sanitizing your living space requires utmost effort, patience and
              sufficient knowledge about the cleaning products. Once you hire
              our service, you can leave all the issues on your back seat. Our
              highly trained staffs are equipped with state-of-the art equipment
              to make your property spic and span.
            </div>
            <div className="mt-3">
              <img src={IMG3} className="img-fluid bg-dotted" alt="" />
            </div>
            <div className="mt-3">
              <button onClick={handleClick} className="tt-btn">
                Request a Quote
                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
              </button>
              <Dialog open={isShown} onClose={handleClick}>
                <DialogTitle>Contact Form</DialogTitle>
                <DialogContent>{<ContactForm />}</DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="bg-sanitize col-lg-6">
          <div className="col-lg-8 p-3 offset-lg-4">
            <span>
              <h2>Commercial Sanitizing</h2>
            </span>
            <div className="mt-3 pr-3">
              Our Commercial Sanitizing &amp; Disinfecting Services incorporate
              specialized treatment processes using EPA Registered Products to
              disinfect a variety of surfaces.
            </div>
            <div className="mt-3">
              <img src={IMG4} className="img-fluid " alt="" />
            </div>
            <div className="mt-3">
              <button onClick={handleClick} className="tt-btn">
                Request a Quote
                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
              </button>
              <Dialog open={isShown} onClose={handleClick}>
                <DialogTitle>Contact Form</DialogTitle>
                <DialogContent>{<ContactForm />}</DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0">
          <img src={IMG2} className="img-fluid bg-dotted w-100" alt="" />
        </div>
      </div>
    </section>
  );
}
