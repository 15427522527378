export default function YouTube() {
  return (
    <section className="bg-dotted section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-8 col-12 col-sm-12">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube-nocookie.com/embed/h8jHG7yEu74?controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
