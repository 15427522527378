import { useState } from "react";

export default function ContactForm() {
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    if (document.getElementById("service").value === "Pest Control")
      setIsShown((current) => !current);
    else setIsShown((current) => false);
  };
  function add(event) {
    event.preventDefault();
    var path = "https://wa.me/917011146759?text=";
    var name = document.getElementById("name").value;
    var service = document.getElementById("service").value;
    service = service !== 'ps' ? service : '';
    var text =
      "Hello, My Name is " +
      name +
      " and I Want to use your " +
      service +
      " Service";
    if (service === "Pest Control") {
      var subservice = document.getElementById("sub-service").value;
      if(subservice !== 'ps')
      text = text + " for " + subservice + " Service";
    }
    path = path + encodeURIComponent(text);
    window.open(path, '_blank', 'noopener,noreferrer').focus();
  }
  return (
    <div className="container">
      <form onSubmit={add}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your name.."
          required
        />

        <label htmlFor="service">Service</label>
        <select onChange={handleClick} id="service" name="service" required>
          <option value="ps">Please Select</option>
          <option value="Pest Control">Pest Control</option>
          <option value="Fire Safety">Fire Safety</option>
          <option value="Security">Security Service</option>
          <option value="House Keeping">House Keeping</option>
        </select>
        {isShown === true ? (
          <div>
            <label htmlFor="sub-service">Sub-Service</label>
            <select id="sub-service" name="sub-service" required>
              <option value="ps">Please Select</option>
              <option value="Termite Control">Termite Control</option>
              <option value="Disinfection Service">Disinfection Service</option>
              <option value="Rodent control">Rodent control</option>
              <option value="Carpet Treatment">Carpet Treatment</option>
              <option value="Vector control">Vector control</option>
              <option value="Weevils Control">Weevils control</option>
            </select>
          </div>
        ) : (
          ""
        )}

        <input type="submit" value="Send" />
      </form>
    </div>
  );
}
