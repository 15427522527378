import IMG1 from "../assets/carosol/m-security.jpg";

export default function ServiceSecurity() {
  return (
    <section className="sp_services_wrapper section relative max-w-6xl max-w-8xl mx-auto px-4 pst_bottompadder70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12 col-sm-12 offset-lg-2 offset-md-2">
            <div className="sp_choose_heading_main_wrapper pst_bottompadder50">
              <h2>
                <span>Who we let, to Protect you</span>
              </h2>
              <p>
                Our staff is a blend of Ex-Servicemen, Ex-Para Military Forces
                and Civilians who are young energetic and willing to take any
                challenging assignment for our clients in accordance with their
                specifications
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-3 text-justify">
          <div className="text-left col-lg-8 col-md-8 col-12 col-sm-12 offset-lg-1 offset-md-1">
            <h4>Parameters &amp; Guidelines for selections</h4>
            <p>
              <li> Minimum Education Standards (High School)</li>
              <li> Physical fitness(Minimum height 5'6", Chsstbuilt) </li>
              <li> Back Ground Verification </li>
              <li>
                The strength should be a mix of all group with maximum being
                from age 25 year-35 year. (Ceiling age for civilian 45 years and
                ex-serviceman 50 years)
              </li>
              <li> Aptitude for the profession </li>
              <li>
                The strength should be a mix of all Communications &amp; Geo.
              </li>
            </p>
          </div>
          <div className="col-lg-3 col-md-3 col-12 col-sm-12">
            <img src={IMG1} className="img-fluid" alt="pest control services" />
          </div>
        </div>
        <div className="row mt-3 text-justify">
          <div className="col-lg-3 col-md-3 col-12 col-sm-12">
            <img src={IMG1} className="img-fluid" alt="pest control services" />
          </div>
          <div className="col-lg-8 col-md-8 col-12 col-sm-12 text-left">
            <div className="text-left mt-3">
              <h4>Selection Process</h4>
              <p>
                The recruitment is done on a regular from individuals coming to
                the office through advertisements, recruitment rallies in
                neighboring villages and recruitment rallies for the Armed and
                Para-Military Forces.
              </p>
            </div>
            <div className="text-left mt-4">
              <h4>Training</h4>
              <p>
                All staff recruited by us are intensive training by experienced
                staff before actually deploying them. We have continuous of "On
                Job" training. We are also imparting training in all services
                for use in emergencies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
