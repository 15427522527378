import Allergens from "../components/Allergens";
import WhatsApp from "../components/Call";
import Connect from "../components/Connect";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroHome from "../components/HeroHome";
import Number from "../components/Numbers";
import OurClients from "../components/OurClients";
import Solutions from "../components/Solutions";
// import OurService from "../components/OurService";
// import Trusted from "../components/Trusted";
import TrustSecurity from "../components/TrustSecurity";
import WhyHire from "../components/WhyHire";
import YouTube from "../components/Youtube";

export default function Home() {
  return (
    <div className="d-flex flex-col min-h-screen overflow-hidden">
      {<Header />}
      {<HeroHome />}
      <main className="grow">
        {<Number />}
        <hr></hr>
        {<Allergens />}
        {<TrustSecurity />}
        <hr></hr>
        {<Solutions />}
        {<WhyHire />}
        {<Connect />}
        {<OurClients />}
        {/* {<Trusted />} */}
        <hr></hr>
        {<WhatsApp />}
        {<YouTube />}
      </main>
      {<Footer />}
    </div>
  );
}
