import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AOS from "aos";
import Home from "./pages/Home";
import "./styles/responsive.css";
import "./styles/main.css";
import "./App.css";
import "../node_modules/aos/dist/aos.css";
import "../node_modules/aos/dist/aos.js";
import AboutPage from "./pages/About";
import PestControl from "./pages/PestControl";
import Contact from "./pages/Contact";
import HouseKeeping from "./pages/HouseKeeping";
import FireFight from "./pages/FireFight";
import Security from "./pages/Security";
import AdminLogin from "./admin/AdminLogin";
import AdminDashboard from "./admin/AdminDashboard";
import AdminEmployee from "./admin/Employee";
import { ProtectedRoute } from "./service/ProtectedRoute";

function App() {
  useEffect(() => {
    AOS.init({
      once: false,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  });
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate replace to="/home" />} />
        <Route
          exact
          path="/admin"
          element={<Navigate replace to="/admin/login" />}
        />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/services/pest-control" element={<PestControl />} />
        <Route
          exact
          path="/services/house-keeping"
          element={<HouseKeeping />}
        />
        <Route exact path="/services/fire-security" element={<FireFight />} />
        <Route exact path="/services/security-service" element={<Security />} />

        {/* <Route exact path="/admin/login" element={<AdminLogin />} />
        
        <Route exact path="/admin/dashboard" element={<ProtectedRoute />}>
          <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
        </Route>
        <Route exact path="/admin/employee" element={<ProtectedRoute />}>
          <Route exact path="/admin/employee" element={<AdminEmployee />} />
        </Route> */}

        {/* <ProtectedRoute
          exact
          path="/admin/dashboard"
          element={<AdminDashboard />}
        /> */}

        <Route path="*" component={() => "404 NOT FOUND"} />
      </Routes>
    </>
  );
}

export default App;
