import IMG1 from "../assets/housekeeping.jpg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./ContactForm";
import { useState } from "react";

export default function AboutHouse() {
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  return (
    <section className="about-services">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div>
              <img
                src={IMG1}
                className="img-fluid"
                alt="pest control services"
              />
            </div>
          </div>
          <div className="col-md-7">
            <div>
              <h1 className="fw-bold text-uppercase fs-2 mb-3">
                Home Keeping in Ghaziabad &amp; NCR
              </h1>
              <p>
                We at Safe Care strongly believe that, tidiness and cleanliness
                are essential for harmony and peace of mind at your work and
                retail space. Hence, it is essential for companies to create the
                right environment for efficiency and productivity at work place
                while maintaining a clear brand image. Professional Housekeeping
                services from Radiance enables our clients to keep their
                environments not only spotlessly clean but also absolutely
                hygienic throughout.
              </p>
              <p className="mt-3">
                Our comprehensive solutionsand reliable housekeeping services in
                Delhi &amp; NCR including regions of Noida, Gurgaon and
                othersensure that our clients receive trained, pleasant, and
                well-groomed crew for all the above service categories.Thereby,
                enabling our clients to focus on their core competence and grow
                further.
              </p>
              <p className="mt-3">
                <button onClick={handleClick} className="tt-btn">
                  Request a Quote
                  <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                </button>
                <Dialog open={isShown} onClose={handleClick}>
                  <DialogTitle>Contact Form</DialogTitle>
                  <DialogContent>{<ContactForm />}</DialogContent>
                </Dialog>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
