import Car from "../assets/harmful.jpg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./ContactForm";
import { useState } from "react";

export default function Allergens() {
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  return (
    <section className="clients_wrapper section relative max-w-6xl max-w-8xl mx-auto px-4 pst_bottompadder70">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 col-sm-12 d-flex align-items-center">
            <div className="w-100 align-items-center text-center">
              <h2>
                <span>Scientifically Proven Effective Against</span>
              </h2>
              <p className="mt-5">
                With customizable cleaning options and competitive rates, our
                services a large range of clients, from schools, medical
                offices, shopping centers, warehouses, dealerships, religious
                centers, professional offices, daycares, retail stores, gyms and
                more.
              </p>
              <div className="mt-5">
                <button onClick={handleClick} className="tt-btn">
                  Request a Quote
                  <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                </button>
                <Dialog open={isShown} onClose={handleClick}>
                  <DialogTitle>Contact Form</DialogTitle>
                  <DialogContent>{<ContactForm />}</DialogContent>
                </Dialog>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 col-sm-12">
            <div>
              <img
                src={Car}
                className="img-fluid"
                alt="pest control services"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
