import IMG1 from "../assets/hero-05.jpg";

export default function AboutPest() {
  return (
    <section className="about-services">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5">
            <div>
              <img
                src={IMG1}
                className="img-fluid"
                alt="pest control services"
              />
            </div>
          </div>
          <div className="col-md-7 text-justify">
            <div>
              <h1 className="fw-bold text-uppercase fs-2 mb-3">
                Pest Control Services in Ghaziabad &amp; NCR
              </h1>
              <p>
                We appreciate to have your precious time to introduce our self,
                as a value added services Provided at SAFE CARE (Lic.
                No.-1820/Gbd/2016) (Formar Name - Jyoti Pest Control (Regd)).We
                aide businesses and respect customers value i.e. quality,
                quantity, cost, time and no wastage. We at disply take on the
                responsibilities of Providing excellent customized, services &amp;
                interest to provide Management services to our customer.
              </p>
              <p className="mt-3">
                We provide Ghaziabad's best Pest control services, It can help
                resolve any kind of pest infestation, wood borers, bed bugs, be
                it cockroaches, ants, house flies, lizards, mosquitoes,
                termites, rodents, spiders, rats, etc. This process safe and
                100% healthy environment for kids, elderly people &amp; pets.
                Provides 100% Relief From All Kind Of Pests For Home &amp;
                Offices At Best Price.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
