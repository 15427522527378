import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";
import WhatsApp from "../components/Call";
import HeroHome from "../components/HeroHome";

export default function Contact() {
  return (
    <div className="d-flex flex-col min-h-screen overflow-hidden">
      {<Header />}
      {<HeroHome />}
      <main className="grow mt-3">
        {/* {<Connect />} */}
        <section className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 text-justify">
              <h1 className="fw-bold text-uppercase fs-2 mb-3">
                Safe Care Office in Ghaziabad
              </h1>
              <p>
                We appreciate to have your precious time to introduce our self,
                as a value added services Provided at SAFE CARE (Lic.
                No.-1820/Gbd/2016) (Formar Name - Jyoti Pest Control (Regd)).We
                aide businesses and respect customers value i.e. quality,
                quantity, cost, time and no wastage. We at disply take on the
                responsibilities of Providing excellent customized, services &amp;
                interest to provide Management services to our customer.
              </p>
              <p className="mt-3">
                We provide Ghaziabad's best Pest control services, It can help
                resolve any kind of pest infestation, wood borers, bed bugs, be
                it cockroaches, ants, house flies, lizards, mosquitoes,
                termites, rodents, spiders, rats, etc. This process safe and
                100% healthy environment for kids, elderly people &amp; pets.
                Provides 100% Relief From All Kind Of Pests For Home &amp;
                Offices At Best Price.
              </p>
            </div>
            <div className="col-lg-4 mt-3">
            <h3 className="mb-3">Contact Us</h3>
              {<ContactForm />}
            </div>
          </div>
        </section>
        <div className="bg-contact-map mt-4">
          <div className="mx-auto pt-3 pb-3 container row align-items-center ">
            <div className="col-md-4">
              <div className="container mb-3">
                <h2>Safe Care Office</h2>
                <p>
                  LGF - 34, Devika Chamber, RDC, Raj Nagar, Ghaziabad
                </p>
                <p> +917011146759</p>
                <p>
                  <a
                    href="mailto:safecare016@gmail.com"
                  >
                    safecare016@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-8">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.5684525209954!2d77.43856191744385!3d28.672637500000008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf1b95c0546e7%3A0x14837b1d9437b26f!2sDevika%20Chambers!5e0!3m2!1sen!2sin!4v1658483018154!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  title="g-map"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        {/* {<Process />} */}
        {<WhatsApp />}

      </main>
      {<Footer />}
    </div>
  );
}
