import AboutHouse from "../components/AboutHouse";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroHome from "../components/HeroHome";
import Number from "../components/Numbers";
import WhyHire from "../components/WhyHire";
import OurClients from "../components/OurClients";
import WhatsApp from "../components/Call";
import HomeService from "../components/HomeService";

export default function HouseKeeping() {
  return (
    <div className="d-flex flex-col min-h-screen overflow-hidden">
      {<Header />}
      {<HeroHome />}
      <main className="grow">
        {<AboutHouse />}
        {<HomeService />}
        {<Number />}
        {<WhyHire />}
        {<OurClients />}
        {<WhatsApp />}

      </main>
      {<Footer />}
    </div>
  );
}
