import React from "react";
import Header from "../components/Header";
import IMG1 from "../assets/logo.png";
import Footer from "../components/Footer";
import HeroHome from "../components/HeroHome";
import OurClients from "../components/OurClients";
import WhatsApp from "../components/Call";
// import Trusted from "../components/Trusted";

export default function AboutPage() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Page content */}
      {<Header />}
      {<HeroHome />}
      <main className="grow mt-14">
        {/*  Page sections */}
        <div className="services_section container">
          <div className="mx-auto max-w-none flex items-center space-x-8 lg:space-x-16 xl:space-x-20 space-y-16 space-y-0 justify-content-center">
            {/* Content */}
            <div className="pt-5">
              <h1 data-aos="zoom-in-up" className="h1 text-center">
                {" "}
                Safe Care{" "}
              </h1>
              <p className="text-center">Caring With Commitment</p>
            </div>
          </div>
          <div className="mb-3 mt-5 text-justify">
            <p className="mb-4">
              We started as a Pest Control Unit in the year 1995 under the name
              and title M/s. Jyoti Pest Control and provided our services to
              various parties at Delhi NCR.
            </p>
            <p className="mb-4">
              Thereafter, we have added Fire Fighting, House Keeping,and
              Security Services on the demands of our Clients and have
              established under the SAFE CARE.
            </p>
            <p className="mb-4">
              We are register with the epartment of Agriculture, Labour and
              MSME, U.P.
            </p>
          </div>
          <div className="row">
            <div className="my-5 col-lg-7 col-md-7 col-sm-12">
              <ul className="list-unstyled">
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex text-center">
                  <span className="text-left col-4">Company Name : </span>SAFE
                  CARE
                </li>
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                  <span className="col-4">Constitution :</span> Proprietor
                </li>
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                  <span className="col-4">Proprietor :</span> Mr. Abhishek Tyagi
                </li>
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                  <span className="col-4">Pan No. :</span> AVVPT9937B
                </li>
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                  <span className="col-4">GST No. :</span> 09AVVPT9937B2ZN
                </li>
                {/* <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                <span className="col-4">GEM User ID :</span> gem17091997/ Psw
                - shiv123@
              </li> */}
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                  <span className="col-4">List of Activities :</span> Security
                  Services,<br></br> House Keeping,<br></br> Pest Control,
                  <br></br> Fire Safety
                </li>
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                  <span className="col-4">Corporate Office :</span> LGF - 34,
                  Devika Chamber, RDC, Raj Nagar, Ghaziabad.
                </li>
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                  <span className="col-4">Contact Person :</span> Mr. Abhishek
                  Tyagi 7011146759, 8802572110
                </li>
                <li className="col-lg-12 col-md-12 col-sm-12 mt-3 d-flex">
                  <span className="col-4">E-mail :</span> safecare016@gmail.com
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img src={IMG1} className="my-5" alt="safe care ghaziabad" />
            </div>
          </div>
        </div>
        {/* {<Trusted />} */}
        {<OurClients />}
        {<WhatsApp />}
      </main>
      {<Footer />}
    </div>
  );
}
