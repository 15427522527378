import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContactForm from "./ContactForm";
import Carousel from "react-bootstrap/Carousel";
import { useMediaQuery } from "react-responsive";
import IMG1 from "../assets/carosol/safecare.jpg";
import MIMG1 from "../assets/carosol/m-safecare.jpg";
import IMG2 from "../assets/carosol/termite.jpg";
import MIMG2 from "../assets/carosol/m-termite.jpg";
import IMG3 from "../assets/carosol/disinfection.jpg";
import MIMG3 from "../assets/carosol/m-disinfection.jpg";
import IMG4 from "../assets/carosol/rodent.jpg";
import MIMG4 from "../assets/carosol/m-rodent.jpg";
import IMG5 from "../assets/carosol/carpet.jpg";
import MIMG5 from "../assets/carosol/m-carpet.jpg";
import IMG6 from "../assets/carosol/vector.jpg";
import MIMG6 from "../assets/carosol/m-vector.jpg";
import Cimg1 from "../assets/control-img-1.jpg";
import Cimg2 from "../assets/control-img-2.jpg";
import Cimg3 from "../assets/control-img-3.jpg";
import Cimg4 from "../assets/control-img-4.jpg";
import Cimg5 from "../assets/control-img-5.jpg";
import Cimg6 from "../assets/control-img-6.jpg";

export default function PestCarousel() {
  const [isShown, setIsShown] = useState(false);
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <div className="top-margin section max-w-6xl max-w-8xl mx-auto">
      <Carousel>
        <Carousel.Item interval={5000}>
          <img
            data-aos="zoom-in"
            data-aos-delay="100"
            className="d-block w-100 c-mobile"
            src={isMobile === false ? IMG1 : MIMG1}
            alt="First slide"
          />
          <div className="TextCont">
            <Carousel.Caption>
              <h3>Safe Care Pest Control</h3>
              <p>Caring with Commitment</p>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            data-aos="zoom-in"
            data-aos-delay="100"
            className="d-block w-100 c-mobile"
            src={isMobile === false ? IMG2 : MIMG2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>Termite Control</h3>
            <p>
              To tackle effectively white ants problem and make the Building
              resistant to termite attack.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false ? IMG3 : MIMG3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Disinfections service</h3>
            <p>
              Against all crawling insects in the household situation i.e.
              cockroaches, Silver fish, Ticks Bugs, Spiders etc.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false ? IMG4 : MIMG4}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Rodent control</h3>
            <p>To bring under control the household and field redents</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false ? IMG5 : MIMG5}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Carpet Treatment</h3>
            <p>To eradicate carpet beetle and moth cloths.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 c-mobile"
            src={isMobile === false ? IMG6 : MIMG6}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Vector control</h3>
            <p>To combat mosquitoes and files effectively.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="insects">
        <div className="container mt-3">
          <div className="row px-2">
            <div className="col-lg-2 col-md-2 col-sm-6">
              <div onClick={handleClick} className="home-images">
                <img
                  src={Cimg5}
                  className="img-fluid"
                  alt="pest control services"
                />
                <p className="small">Termite Control </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6">
              <div onClick={handleClick} className="home-images">
                <img
                  src={Cimg1}
                  className="img-fluid"
                  alt="pest control services"
                />
                <p className="small">Cockroach Control </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6">
              <div onClick={handleClick} className="home-images">
                <img
                  src={Cimg2}
                  className="img-fluid"
                  alt="pest control services"
                />
                <p className="small">Fly Control</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6">
              <div onClick={handleClick} className="home-images">
                <img
                  src={Cimg3}
                  className="img-fluid"
                  alt="pest control services"
                />
                <p className="small">Sanitation service</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6">
              <div onClick={handleClick} className="home-images">
                <img
                  src={Cimg4}
                  className="img-fluid"
                  alt="pest control services"
                />
                <p className="small">Mosquito Control</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6">
              <div onClick={handleClick} className="home-images">
                <img
                  src={Cimg6}
                  className="img-fluid"
                  alt="pest control services"
                />
                <p className="small">Ant Control</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={isShown} onClose={handleClick}>
        <DialogTitle>Contact Form</DialogTitle>
        <DialogContent>{<ContactForm />}</DialogContent>
      </Dialog>
    </div>
  );
}
