import IMG1 from "../assets/control-img-5.jpg";
import IMG2 from "../assets/carosol/m-disinfection.jpg";
import IMG3 from "../assets/carosol/m-rodent.jpg";
import IMG4 from "../assets/carosol/m-carpet.jpg";
import IMG5 from "../assets/control-img-4.jpg";
import IMG6 from "../assets/weevils.jpg";

export default function OurService() {
  return (
    <section className="sp_services_wrapper section relative max-w-6xl max-w-8xl mx-auto px-4 pst_bottompadder70">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12 col-sm-12 offset-lg-2 offset-md-2">
            <div className="sp_choose_heading_main_wrapper pst_bottompadder50">
              <h2>
                <span>Our Effective Pest Services</span>
              </h2>
              <p>
                Disply is a Govt. approved Uttar Pradesh 1995. We are providing
                our services to more than 44 organizations at Delhi NCR,
                Haridwar &amp; Dehradun. We offer our guaranteed services for
                Termite control, Disinfections services, Rodent control, carpet
                treatment and vector control.
              </p>
            </div>
          </div>
        </div>
        <div className="text-left row h-10rem">
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <img src={IMG1} className="img-fluid" alt="pest control services" />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <h3>Termite Control</h3>
            <span className="mt-3">
              To tackle effectively white ants problem and make the Building
              resistant to termite attack.
            </span>
            <div className="mt-3">
              <li>Fast-acting termite control</li>
              <li>Long lasting treatment </li>
              <li>Drilling on equal distances without disturbing aesthetics</li>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center mt-2 mb-2">
            <button className="button-service mt-4 mb-2">Book Service</button>
            <button className="button-download">Download Boucher</button>
          </div>
        </div>
        <div className="text-left row h-10rem">
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <img src={IMG2} className="img-fluid" alt="pest control services" />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <h3>Disinfections service</h3>
            <span className="mt-3">
              Against all crawling insects in the household situation i.e.
              cockroaches, Silver fish, Ticks Bugs, Spiders etc.
            </span>
            <div className="mt-3">
              <li>
                Environment friendly: No fragrance or staining after the
                treatment{" "}
              </li>
              <li>Biohazard disposal as per compliance requirement </li>
              <li>EDS experts decontaminate before they leave the premise </li>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center mt-2 mb-2">
            <button className="button-service mt-4 mb-2">Book Service</button>
            <button className="button-download">Download Boucher</button>
          </div>
        </div>
        <div className="text-left row h-10rem">
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <img src={IMG3} className="img-fluid" alt="pest control services" />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <h3>Rodent Control</h3>
            <span className="mt-3">
              To bring under control the household and field redents
            </span>
            <div className="mt-3">
              <li>
                Initial Inspection covers the identification of rodent entry
                points{" "}
              </li>
              <li>
                Proofing against rodents by sealing all rodent entry points{" "}
              </li>
              <li>Trapping internal rodent population </li>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center mt-2 mb-2">
            <button className="button-service mt-4 mb-2">Book Service</button>
            <button className="button-download">Download Boucher</button>
          </div>
        </div>
        <div className="text-left row h-10rem">
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <img src={IMG4} className="img-fluid" alt="pest control services" />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <h3>Carpet Treatment</h3>
            <span className="mt-3">
              To eradicate carpet beetle and moth cloths
            </span>
            <div className="mt-3">
              <li>For carpet cleaning we use only trusted brand </li>
              <li>
                Vacuum cleaner (wet and dry) for carpet wet and dry vacuum
                cleaning,{" "}
              </li>
              {/* <li> </li> */}
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center mt-2 mb-2">
            <button className="button-service mt-4 mb-2">Book Service</button>
            <button className="button-download">Download Boucher</button>
          </div>
        </div>
        <div className="text-left row h-10rem">
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <img src={IMG5} className="img-fluid" alt="pest control services" />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <h3>Vector Control</h3>
            <span className="mt-3">
              To combat mosquitoes and files effectively
            </span>
            <div className="mt-3">
              <li>Indoor residual insecticide spraying (IRS) </li>
              <li>Chemical larvicides </li>
              <li>Monomolecular films and oils </li>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center mt-2 mb-2">
            <button className="button-service mt-4 mb-2">Book Service</button>
            <button className="button-download">Download Boucher</button>
          </div>
        </div>
        <div className="text-left row h-10rem">
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <img src={IMG6} className="img-fluid" alt="pest control services" />
          </div>
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <h3>Grain Weevils Control</h3>
            <span className="mt-3">
              To combat Grain weevils effectively
            </span>
            <div className="mt-3">
              <li>calamus rhizome powder </li>
              <li>Freeze and kill it </li>
              <li>Bay or neem leaves </li>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex flex-column align-items-center mt-2 mb-2">
            <button className="button-service mt-4 mb-2">Book Service</button>
            <button className="button-download">Download Boucher</button>
          </div>
        </div>
      </div>
    </section>
  );
}
