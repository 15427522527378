import Principle1 from "../assets/principle1.png";
import Principle2 from "../assets/principle2.png";
import Principle3 from "../assets/principle3.png";

export default function Process() {
  return (
    <section className="relative ">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12 col-sm-12 offset-lg-2 offset-md-2">
            <div className="pst_bottompadder50 text-center">
              <h2>
                <span>Work Principle</span>
              </h2>
              <p>
                We are continuously improving our services to provide best valur
                to client. our competitive advantage lies in culture of "can do"
                attitude that relished change, exploits innovation and ensures
                delivery
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="d-flex">
              <img
                src={Principle1}
                className="img-responsive mx-auto"
                alt="img"
              />
            </div>
            <h4 className="text-center pst_bottompadder10">
              <a href="/#">Preparatory Arrangement</a>
            </h4>
            <p className="text-center">
              We come to you to inspect the area, to prepare it for future
              disinfection, and to take into concern your wishes.
            </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="d-flex">
              <img
                src={Principle2}
                className="img-responsive mx-auto"
                alt="img"
              />
            </div>
            <h4 className="text-center pst_bottompadder10">
              <a href="/#">disinfection</a>
            </h4>
            <p className="text-center">
              We carry out the disinfection during couple of hours depending on
              house size and amount of work.
            </p>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="d-flex">
              <img
                src={Principle3}
                className="img-responsive mx-auto"
                alt="img"
              />
            </div>
            <h4 className="text-center pst_bottompadder10">
              <a href="/#">Removal After Disinfection</a>
            </h4>
            <p className="text-center">
              We do all the removal after disinfection was successfully done.
              You’ll have no worries about that!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
