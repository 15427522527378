import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import BusinessIcon from '@mui/icons-material/Business';
export default function OurClients() {
  return (
    <section className="clients_wrapper section relative max-w-6xl max-w-8xl mx-auto px-4 pst_bottompadder70">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-12 col-sm-12 d-flex align-items-center">
            <div className="w-100 align-items-center text-center">
              <h2>
                <span>Our Clients</span>
              </h2>
              <p className="mt-5">
                Meeting &amp; exceeding customer's expectations is essence of our expertise for more than 28 Years.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 col-sm-12">
            <div className="d-flex">
              <div data-aos="zoom-in-up" data-aos-delay="500" className="mt-5 mr-2 w-33p">
                <div className="h-10rem h-m5rem bg-yellow align-items-center d-flex">
                  <div className="d-flex flex-column align-items-center w-100 text-client">
                    <span>10+</span>
                    <span>GOVT. Office</span>
                    <BusinessIcon></BusinessIcon>
                  </div>
                </div>
                <div className="h-10rem h-m5rem mt-2 bg-green align-items-center d-flex">
                  <div className="d-flex flex-column align-items-center w-100 text-client">
                    <span>20+</span>
                    <span>Industries</span>
                    <ApartmentIcon></ApartmentIcon>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in-down" data-aos-delay="500" className="w-33p mr-2">
                <div className="h-10rem h-m5rem bg-green align-items-center d-flex">
                  <div className="d-flex flex-column align-items-center w-100 text-client">
                    <span>50+</span>
                    <span>Residential</span>
                    <HomeWorkIcon></HomeWorkIcon>
                  </div>
                </div>
                <div className="h-10rem h-m5rem mt-2 bg-pink align-items-center d-flex">
                  <div className="d-flex flex-column align-items-center w-100 text-client">
                    <span>10+</span>
                    <span>Hospitals</span>
                    <LocalHospitalIcon></LocalHospitalIcon>
                  </div>
                </div>
              </div>
              <div data-aos="zoom-in-up" data-aos-delay="500" className="mt-5 w-33p">
                <div className="h-10rem h-m5rem bg-yellow align-items-center d-flex">
                  <div className="d-flex flex-column align-items-center w-100 text-client">
                    <span>10+</span>
                    <span>Banks</span>
                    <BusinessIcon></BusinessIcon>
                  </div>
                </div>
                <div className="h-10rem h-m5rem mt-2 bg-green align-items-center d-flex">
                  <div className="d-flex flex-column align-items-center w-100 text-client">
                    <span>10+</span>
                    <span>Hotels</span>
                    <ApartmentIcon></ApartmentIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
