// import Connect from "../components/Connect";
import AboutPest from "../components/AboutPest";
import Allergens from "../components/Allergens";
import Footer from "../components/Footer";
import Header from "../components/Header";
import OurClients from "../components/OurClients";
// import HeroHome from "../components/HeroHome";
import OurService from "../components/OurService";
import PestCarousel from "../components/PestCarousel";
import Process from "../components/Process";
import RCSanitizing from "../components/RCSanitizing";
import Solutions from "../components/Solutions";
import WhatsApp from "../components/Call";
import WhyHire from "../components/WhyHire";
import YouTube from "../components/Youtube";

export default function PestControl() {
  return (
    <div className="d-flex flex-col min-h-screen overflow-hidden">
      {<Header />}
      {<PestCarousel />}
      <main className="grow">
        {<AboutPest />}
        {/* {<Connect />} */}
        {<Allergens />}
        {<RCSanitizing />}
        {<Solutions />}
        {<OurService />}
        {<Process />}
        {<WhyHire />}
        {<OurClients/>}
        <hr></hr>
        {<YouTube />}
        {/* {<Process />} */}
        {<WhatsApp />}

      </main>
      {<Footer />}
    </div>
  );
}
