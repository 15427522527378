import AboutFire from "../components/AboutFire";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroHome from "../components/HeroHome";
import FireYouth from "../assets/fire-img.jpg";
import WhatsApp from "../components/Call";
import FireSafety from "../components/FireSafety";

export default function FireFight() {
  return (
    <div className="d-flex flex-col min-h-screen overflow-hidden">
      {<Header />}
      {<HeroHome />}
      <main className="grow">
        {<AboutFire />}
        {<FireSafety />}
        {<WhatsApp />}

      </main>
      {/* <section>
        <div className="container">
          <div className="row m-5">
            <div className="col-lg-6">
              <img
                src="https://source.unsplash.com/1400x1400/?code,technology"
                className="img-fluid"
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h1>
                <b>Fire Safety Tips</b>
              </h1>
              <h3 className="mt-4 text-danger">
                <i>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum.
                </i>
              </h3>
              <ul>
                <li className="blockquote mt-5">
                  <span className="glyphicon glyphicon-ok-circle">
                    Install smoke alarm in your home
                  </span>
                </li>
                <li className="blockquote mt-4">
                  <span className="glyphicon glyphicon-ok-circle">
                    Test smoke alarm every month
                  </span>
                </li>
                <li className="blockquote mt-4">
                  <span className="glyphicon glyphicon-ok-circle">
                    About a fire escape plan and practice
                  </span>
                </li>
                <li className="blockquote mt-4">
                  <span className="glyphicon glyphicon-ok-circle">
                    Think of fire before it starts
                  </span>
                </li>
                <li className="blockquote mt-4">
                  <span className="glyphicon glyphicon-ok-circle">
                    Read fire stop prevention Program
                  </span>
                </li>
                <li className="blockquote mt-4">
                  <span className="glyphicon glyphicon-ok-circle">
                    get out before the smoke gets thick
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}

      <section className="p-0">
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="false"
        >
          <div className="carousel-indicators"></div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="https://source.unsplash.com/1200x250/?dark,fire"
                className="d-block w-100"
                alt="..."
              />
              <div className="carousel-caption d-none d-md-block">
                <h1>
                  <b>HEROES WANTED!</b>
                </h1>
                <h4>Join our department</h4>
                <a
                  className="btn btn-danger btn-lg mt-4"
                  href="/#"
                  role="button"
                >
                  <p className="m-1">APPLY HERE</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-youth-invention">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Youth Fire Stop Prevention & Invention Program</h2>
              <p className="blockquote mt-4">
                Youth Fire Stop is a program designed to prevent misuse of fire
                by youth in our community and to educate and intervene when
                endangerment involving fire occurs. Youth Fire Stop has been
                streamlined in order to make the program more effective.
              </p>
              <a className="btn btn-danger btn-lg mt-4" href="/#" role="button">
                <p className="m-1">VIEW REFERRAL FORM</p>
              </a>
            </div>
            <div className="col-md-6">
              <img src={FireYouth} alt="" />
            </div>
          </div>
        </div>
      </section>
      {<Footer />}
    </div>
  );
}
